//
//
// ================================== imports ==================================
@import "./assets/scss/theme.scss";

@import "./assets/js/simplebar/simplebar.min.css";

@import "~@ng-select/ng-select/themes/default.theme.css";

@import "../node_modules/ngx-toastr/toastr.css";

@import "./assets/scss/loading.min.css";
@import "../node_modules/ldbutton/dist/ldbtn.min.css";

// @font-face {
//   src: url("assets/fonts/OmnesLatin-SemiBold-2ee0127433dca84e1c231f14344b7b15.woff2");
//   font-family: "Omnes";
// }
@font-face {
  src: url("assets/fonts/OmnesLatin-Bold-599a46bebd7b761a25f4c955daeb87b3.woff2");
  font-family: "Omnes";
}
// ================================== imports ==================================
//
//
// ================================== settings ==================================
html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  line-height: 1.5;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

// ================================== settings ==================================
//
//
// ================================== fonts ==================================
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Omnes";
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  text-rendering: optimizeLegibility;
  font-weight: 600;
}
// ================================== fonts ==================================
//
//
// ================================== toastr ==================================
.toast-container {
  .ngx-toastr {
    padding: 15px !important;
    border-radius: 15px;
    // box-shadow: 0 24px 24px 0 rgb(32 33 37 / 24%),
    //   0 0 24px 0 rgb(32 33 37 / 12%);
  }
  .ngx-toastr.toast-success {
    background: #36d6ab;
    .toast-message {
      width: 100%;
      font-size: 0.9rem;
      font-weight: 300;
    }
  }
  .ngx-toastr.toast-error {
    background: #f74f78;
    .toast-message {
      width: 100%;
      font-size: 0.9rem;
      font-weight: 300;
    }
  }
}
// ================================== toastr ==================================
//
//
// ================================== inputs ==================================
$gray: #5a5a5a;
input {
  color: $gray;
}
input + label {
  color: $gray;
}
input:-webkit-autofill {
  -webkit-text-fill-color: $gray;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
input:-moz-autofill {
  -moz-text-fill-color: $gray;
  -moz-box-shadow: 0 0 0px 1000px white inset;
}
input:-o-autofill {
  -o-text-fill-color: $gray;
  -o-box-shadow: 0 0 0px 1000px white inset;
}
input:-khtml-autofill {
  -khtml-text-fill-color: $gray;
  -khtml-box-shadow: 0 0 0px 1000px white inset;
}

input:focus:-webkit-autofill {
  -webkit-text-fill-color: $gray;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
input:focus:-moz-autofill {
  -moz-text-fill-color: $gray;
  -moz-box-shadow: 0 0 0px 1000px white inset;
}
input:focus:-o-autofill {
  -o-text-fill-color: $gray;
  -o-box-shadow: 0 0 0px 1000px white inset;
}
input:focus:-khtml-autofill {
  -khtml-text-fill-color: $gray;
  -khtml-box-shadow: 0 0 0px 1000px white inset;
}
// invalid input
.form-invalid-input-alert {
  margin-top: 5px;
  color: red;
  font-size: 12px;
}
// ================================== inputs ==================================
//
//
// ================================== rating ==================================
.rating {
  .sr-only {
    display: none !important;
  }
}
.custom-rating {
  position: relative;
  display: inline-block;
  font-size: 15px;
  color: rgba(255, 196, 0, 1);

  .full {
    color: #e59819;
  }
  .half {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: #e59819;
  }
}
// ================================== rating ==================================
//
//
// ================================== pagination ==================================
ngb-pagination {
  .pagination {
    display: flex;
    flex-wrap: wrap;
    .page-link {
      .sr-only {
        display: none;
      }
    }
  }
}
// ================================== pagination ==================================
//
//
// ================================== spinner ==================================
.spinner-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}
// ================================== spinner ==================================
//
//
// ================================== buttons ==================================
.btn {
  // border-radius: 0;
}
.disabled-button {
  background: darkgray !important;
  border: 0px !important;
}
// ================================== buttons ==================================
//
//
// ================================== popover ==================================
.popover {
  width: 200px;
}
// ================================== popover ==================================
//
//
// ================================== ng select ==================================
.ng-select .ng-select-container {
  min-height: 44px;
  line-height: 2rem;
  border-radius: 0.75rem;
}
.ng-select.ng-select-single .ng-select-container {
  height: 44px;
}
// ================================== ng select ==================================
