//
// Social buttons
// --------------------------------------------------

// Base styles

.btn-social {
  display: inline-block;
  width: $btn-social-size;
  height: $btn-social-size;
  padding: 0;
  border: $btn-social-border-width solid transparent;
  transition: $btn-social-transition;
  @include border-radius($btn-social-border-radius);
  background-color: $btn-social-dark-bg;
  color: $btn-social-dark-color;
  font-size: $btn-social-font-size;
  text: {
    align: center;
    decoration: none !important;
  }
  > i {
    line-height: $btn-social-size - 0.125rem;
  }

  &:hover,
  &.active {
    color: $btn-social-dark-hover-color;
  }
  &:focus,
  &.active {
    outline: none;
  }
  &:active {
    transition: none;
    box-shadow: none !important;
  }

  &.bs-light {
    background-color: $btn-social-light-bg;
    color: $btn-social-light-color;
    &:hover,
    &.active {
      background-color: $btn-social-light-hover-bg !important;
      box-shadow: none !important;
    }
  }
}

// Sizing

.bs-lg {
  width: $btn-social-size-lg;
  height: $btn-social-size-lg;
  @include border-radius($btn-social-border-radius-lg);
  font-size: $btn-social-font-size-lg;
  > i {
    line-height: $btn-social-size-lg - 0.125rem;
  }
}
.bs-sm {
  width: $btn-social-size-sm;
  height: $btn-social-size-sm;
  @include border-radius($btn-social-border-radius-sm);
  font-size: $btn-social-font-size-sm;
  > i {
    line-height: $btn-social-size-sm - 0.125rem;
  }
}

// Outline variant

.bs-outline {
  border-color: $btn-social-dark-border-color;
  background-color: transparent;
  &.bs-light {
    border-color: $btn-social-light-border-color;
    background-color: transparent;
  }
  &:hover,
  &.active {
    border-color: transparent;
  }
}

// Round shape

.bs-round {
  border-radius: 50% !important;
}

// Brand colors

.bs-facebook:hover,
.bs-facebook.active {
  background-color: #3b5998;
  &.bs-light {
    color: #3b5998;
  }
}
.bs-discord-icon-svgrepo-com:hover,
.bs-discord-icon-svgrepo-com.active {
  background-color: #7289da;
  &.bs-light {
    color: #7289da;
  }
}
.bs-zoom:hover,
.bs-zoom.active {
  background-color: #2D8CFF;
  &.bs-light {
    color: #2D8CFF;
  }
}
.bs-signal-1:hover,
.bs-signal-1.active {
  background-color: #3F78ED;
  &.bs-light {
    color: #3F78ED;
  }
}
.bs-microsoft-teams:hover,
.bs-microsoft-teams.active {
  background-color: #464EB8;
  &.bs-light {
    color: #464EB8;
  }
}
.bs-twitter:hover,
.bs-twitter.active {
  background-color: #1da1f2;
  &.bs-light {
    color: #1da1f2;
  }
}
.bs-instagram:hover,
.bs-instagram.active {
  background-color: #5851db;
  &.bs-light {
    color: #5851db;
  }
}
.bs-google:hover,
.bs-google.active {
  background-color: #ea4335;
  &.bs-light {
    color: #ea4335;
  }
}
.bs-linkedin:hover,
.bs-linkedin.active {
  background-color: #0077b5;
  &.bs-light {
    color: #0077b5;
  }
}
.bs-pinterest:hover,
.bs-pinterest.active {
  background-color: #bd081c;
  &.bs-light {
    color: #bd081c;
  }
}
.bs-tumblr:hover,
.bs-tumblr.active {
  background-color: #35465c;
  &.bs-light {
    color: #35465c;
  }
}
.bs-behance:hover,
.bs-behance.active {
  background-color: #1769ff;
  &.bs-light {
    color: #1769ff;
  }
}
.bs-dribbble:hover,
.bs-dribbble.active {
  background-color: #ea4c89;
  &.bs-light {
    color: #ea4c89;
  }
}
.bs-vk:hover,
.bs-vk.active {
  background-color: #45668e;
  &.bs-light {
    color: #45668e;
  }
}
.bs-odnoklassniki:hover,
.bs-odnoklassniki.active {
  background-color: #ed812b;
  &.bs-light {
    color: #ed812b;
  }
}
.bs-skype:hover,
.bs-skype.active {
  background-color: #00aff0;
  &.bs-light {
    color: #00aff0;
  }
}
.bs-hangouts:hover,
.bs-hangouts.active {
  background-color: #0f9d58;
  &.bs-light {
    color: #0f9d58;
  }
}
.bs-messenger:hover,
.bs-messenger.active {
  background-color: #0084ff;
  &.bs-light {
    color: #0084ff;
  }
}
.bs-viber:hover,
.bs-viber.active {
  background-color: #59267c;
  &.bs-light {
    color: #59267c;
  }
}
.bs-telegram:hover,
.bs-telegram.active {
  background-color: #0088cc;
  &.bs-light {
    color: #0088cc;
  }
}
.bs-youtube:hover,
.bs-youtube.active {
  background-color: #ff0000;
  &.bs-light {
    color: #ff0000;
  }
}
.bs-figma:hover,
.bs-figma.active {
  background-color: #a259ff;
  &.bs-light {
    color: #a259ff;
  }
}
.bs-vimeo:hover,
.bs-vimeo.active {
  background-color: #1ab7ea;
  &.bs-light {
    color: #1ab7ea;
  }
}
.bs-wechat:hover,
.bs-wechat.active {
  background-color: #7bb32e;
  &.bs-light {
    color: #7bb32e;
  }
}
.bs-github:hover,
.bs-github.active {
  background-color: #4078c0;
  &.bs-light {
    color: #4078c0;
  }
}
.bs-gitlab:hover,
.bs-gitlab.active {
  background-color: #fc6d26;
  &.bs-light {
    color: #fc6d26;
  }
}
.bs-paypal:hover,
.bs-paypal.active {
  background-color: #003087;
  &.bs-light {
    color: #003087;
  }
}
.bs-slack:hover,
.bs-slack.active {
  background-color: #3eb991;
  &.bs-light {
    color: #3eb991;
  }
}
.bs-trello:hover,
.bs-trello.active {
  background-color: #0079bf;
  &.bs-light {
    color: #0079bf;
  }
}
.bs-twitch:hover,
.bs-twitch.active {
  background-color: #6441a5;
  &.bs-light {
    color: #6441a5;
  }
}
.bs-rss:hover,
.bs-rss.active {
  background-color: #f26522;
  &.bs-light {
    color: #f26522;
  }
}
.bs-email:hover,
.bs-email.active {
  background-color: $primary;
  &.bs-light {
    color: $primary;
  }
}
.bs-whatsapp:hover,
.bs-whatsapp.active {
  background-color: #075e54;
  &.bs-light {
    color: #075e54;
  }
}
